import { Button, Form, Input } from "antd";
import React from "react";

const ChatMessages = ({ selectedChat, user, replyContent, handleReplyChange, handleReplySubmit }) => {
    return (
        <div>
            <h2 className="font-bold mb-2 pb-2 border-b">{selectedChat.user}</h2>
            <h3 className="font-bold text-base mb-3 border-b-2">{selectedChat.propertyTitle}</h3>
            <div
                id="messages"
                style={{ maxHeight: "500px" }}
                className="pr-5 overflow-auto scrollbar-thin scrollbar-track-primary scrollbar-thumb-primary-100"
            >
                {selectedChat.messages.map((message) => (
                    <div
                        key={message.id}
                        className={
                            message.senderUuid === user.uuid
                                ? "bg-teal-200 rounded mb-2 p-2 w-2/3 ml-auto"
                                : "bg-gray-200 rounded mb-2 p-2 w-2/3"
                        }
                    >
                        <div className="relative">
                            {message.content}
                        </div>
                        <div className="text-xs text-right">{new Date(message.createdAt.date).toLocaleString('fi-FI')}</div>
                    </div>
                ))}
            </div>
            <Form layout="horizontal" className="mt-5 flex w-full">
                <Form.Item className="flex-1">
                    <Input
                        className="w-full"
                        type="text"
                        value={replyContent}
                        onChange={handleReplyChange}
                        placeholder="Kirjoita viesti"
                        onKeyUp={(e) => e.key === "Enter" && handleReplySubmit()}
                    />
                </Form.Item>
                <Form.Item className="ml-5">
                    <Button type="primary" onClick={handleReplySubmit}>
                        Lähetä
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ChatMessages;
