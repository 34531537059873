import React, { useState, useEffect, useContext } from "react";
import { Button, Spin } from "antd";
import api from "../../services/Api";
import AuctionMiniCard from "./AuctionMiniCard";
import { AuthContext } from "../../context/AuthContext";
import AuctionFeaturePurchaseModal from "../modals/AuctionFeatureBuyModal";

const AuctionFeaturedList = () => {
    const { user } = useContext(AuthContext);
    const [auctions, setAuctions] = useState([]);
    const [itemsWithPromotions, setItemsWithPromotions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [isAuctionFeaturePurchaseModalOpen, setIsAuctionFeaturePurchaseModalOpen] = useState(false);

    const pageSize = 8;
    const refreshInterval = 20000;

    const promotion = (
        <div className="flex flex-col items-center justify-center h-full rounded bg-white border border-gray-300 text-base">
            <div className="mb-2">Oma ilmoitus tähän?</div>
            <Button type="primary" onClick={() => setIsAuctionFeaturePurchaseModalOpen(true)}>
                Lisää ilmoitus
            </Button>
        </div>
    );

    useEffect(() => {
        fetchAuctions();
    }, []);

    useEffect(() => {
        const items = [...auctions];
        if (auctions.length > 0) {
            items.splice(2, 0, "promotion");
        }
        setItemsWithPromotions(items);
    }, [auctions]);

    useEffect(() => {
        const totalPages = Math.ceil(itemsWithPromotions.length / pageSize);

        const interval = setInterval(() => {
            setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
        }, refreshInterval);

        return () => clearInterval(interval);
    }, [itemsWithPromotions.length]);

    const fetchAuctions = async () => {
        setLoading(true);

        try {
            const response = await api.getFeaturedAuctions();
            setAuctions(response);
        } catch (error) {
            console.error("Error fetching featured auctions:", error);
        } finally {
            setLoading(false);
        }
    };

    const getDisplayedItems = () => {
        const startIndex = currentPage * pageSize;
        const endIndex = startIndex + pageSize;
        return itemsWithPromotions.slice(startIndex, endIndex);
    };

    const displayedItems = getDisplayedItems();

    return (
        <div className="relative">
            {loading ? (
                <Spin />
            ) : (
                <>
                    <div className="grid gap-5 xl:grid-cols-8 md:grid-cols-4">
                        {displayedItems.map((item, index) => {
                            if (item === "promotion") {
                                return (
                                    <div
                                        key="promotion"
                                        className="animate-fadeIn opacity-0"
                                        style={{ animationDelay: `${index * 100}ms` }}
                                    >
                                        {promotion}
                                    </div>
                                );
                            } else {
                                return (
                                    <div
                                        key={item.number}
                                        className="animate-fadeIn opacity-0"
                                        style={{ animationDelay: `${index * 100}ms` }}
                                    >
                                        <AuctionMiniCard auction={item} />
                                    </div>
                                );
                            }
                        })}
                    </div>
                    {user && (
                        <AuctionFeaturePurchaseModal
                            isAuctionFeaturePurchaseModalOpen={isAuctionFeaturePurchaseModalOpen}
                            onClose={() => setIsAuctionFeaturePurchaseModalOpen(false)}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default AuctionFeaturedList;
