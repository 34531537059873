import React, { useState, useEffect } from "react";
import { Form, Input, Button, Spin, Checkbox, InputNumber, Tooltip, message, Modal } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import api from "../../services/Api";
import ContractPersonForm from "./ContractPersonForm";

dayjs.extend(utc);
dayjs.extend(timezone);

const ContractForm = ({ auction }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [paymentDueDate, setPaymentDueDate] = useState("");
    const [isCompany, setIsCompany] = useState({
        seller: false,
        buyer: false,
    });
    const [closeContract, setCloseContract] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFormConfirmed, setIsFormConfirmed] = useState(false);
    const [isClosed, setIsClosed] = useState(false);
    const [product, setProduct] = useState(false);
    const [formHasErrors, setFormHasErrors] = useState(false);

    const handleCheckboxChange = (e) => {
        setIsCompany({ ...isCompany, [e.target.id]: e.target.checked });
    };

    const mapPerson = (person) => ({
        email: person.email || "",
        firstname: person.firstname || "",
        lastname: person.lastname || "",
        address: person.address || "",
        zipcode: person.zipcode || "",
        city: person.city || "",
        phonenumber: person.phonenumber || "",
        country: person.country || "",
        ssn: person.ssn || "",
        role: person.role || "",
        companyName: person.companyName || "",
        businessId: person.businessId || "",
        mandateName: person.mandateName || "",
        mandatePhonenumber: person.mandatePhonenumber || "",
        mandateEmail: person.mandateEmail || "",
        mandateSsn: person.mandateSsn || "",
    });

    const [initialData, setInitialData] = useState({
        paymentDueDate: dayjs().add(14, "day").format("D.M.YYYY"),
        salePrice: auction.auctionType === "rent" ? auction.startPrice : auction.acceptedBid.amount,
        accountNumber: "",
        accountOwner: "",
        info: "",
        sellers: [mapPerson(auction.userDetails)],
        buyers: [mapPerson(auction.acceptedBid.user)],
        closeContract: false,
    });

    const getProductPrice = async () => {
        let productCode = "";

        if (auction.auctionType === "rent") {
            productCode = "service-2";
        } else {
            productCode = "service-3";
        }

        try {
            const response = await api.getProduct(productCode);
            setProduct(response);
        } catch (error) {
            console.log("Tuotteen hinnan hakeminen epäonnistui");
        }
    };

    useEffect(() => {
        getProductPrice();
    }, []);

    // Fetch contract data or use auction defaults
    useEffect(() => {
        const fetchContractData = async () => {
            setLoading(true);

            try {
                const response = await api.getContract(auction.uuid);
                const { paymentDueDate, salePrice, accountNumber, accountOwner, info, persons, closed } = response;

                const sellers = persons.filter((person) => person.role === "seller");
                const buyers = persons.filter((person) => person.role === "buyer");

                if (sellers[0].companyName) {
                    setIsCompany({ ...isCompany, seller: true });
                }

                if (buyers[0].companyName) {
                    setIsCompany({ ...isCompany, buyer: true });
                }

                form.setFieldsValue({
                    paymentDueDate: paymentDueDate,
                    salePrice,
                    accountNumber,
                    accountOwner,
                    info,
                    sellers,
                    buyers,
                });

                setIsClosed(closed);

                setPaymentDueDate(dayjs(paymentDueDate));
            } catch (error) {
                // No contract exists, so use default values from auction
                form.setFieldsValue(initialData);
            } finally {
                setLoading(false);
            }
        };

        fetchContractData();
    }, [auction.uuid, form]);

    const onAddPerson = (type) => {
        const currentValues = form.getFieldValue(type) || [];
        const newPerson = mapPerson({});

        form.setFieldsValue({
            [type]: [...currentValues, newPerson],
        });
    };

    const onFinish = async (values) => {
        if (closeContract && !isFormConfirmed) {
            setIsModalOpen(true);
            return;
        }

        setLoading(true);
        setFormHasErrors(false);

        try {
            const sellersWithRoles = values.sellers.map((seller) => ({
                ...seller,
                role: "seller",
            }));

            const buyersWithRoles = values.buyers.map((buyer) => ({
                ...buyer,
                role: "buyer",
            }));

            const allPersons = [...sellersWithRoles, ...buyersWithRoles];
            const finalizeContract = values.finalize || false;

            const payload = {
                persons: allPersons,
                finalize: finalizeContract,
                paymentDueDate: paymentDueDate,
                salePrice: values.salePrice,
                accountNumber: values.accountNumber,
                accountOwner: values.accountOwner,
                info: values.info,
                closeContract: closeContract,
                isCompany: isCompany,
            };

            await api.saveContract(auction.uuid, payload);

            if (finalizeContract) {
                const payload = {
                    productCode: product.code,
                    price: product.price,
                    redirectUrl: `${window.location.origin}/oma-sivu/sopimus/${auction.uuid}`,
                    auctionUuid: auction?.uuid,
                };

                try {
                    const response = await api.createPayment(payload);

                    document.location.href = response.href;
                } catch (error) {
                    console.error("Error during payment processing: ", error);
                }
            }

            message.success("Sopimus tallennettiin onnistuneesti");
        } catch (error) {
            message.error("Sopimuksen tallentaminen epäonnistui");
        } finally {
            setLoading(false);

            if (closeContract) {
                setIsClosed(true);
            }
        }
    };

    const handleDateChange = (date) => {
        setPaymentDueDate(date);
    };

    // Modal Handlers
    const handleOk = () => {
        setIsModalOpen(false);
        setIsFormConfirmed(true); // Mark the form as confirmed
        form.submit(); // Resubmit the form
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <Spin spinning={loading}>
            <div className="bg-gray-100 rounded p-5 mb-5">
                {isClosed && (
                    <div className="bg-red-100 border border-red-400 text-red-700 p-3 rounded mb-5">
                        Tämä sopimus on lähetetty allekirjoitettavaksi eikä sitä voi enää muokata.
                    </div>
                )}
                <Form
                    form={form}
                    name="contractForm"
                    onFinish={onFinish}
                    onFinishFailed={() => setFormHasErrors(true)}
                    initialValues={initialData}
                    layout="vertical"
                    disabled={isClosed}
                >
                    <div className="flex items-center gap-5">
                        <Form.Item
                            label="Eräpäivä"
                            name="paymentDueDate"
                            rules={[{ required: true, message: "Eräpäivä on pakollinen" }]}
                        >
                            <Input type="text" />
                            {/* <DatePicker
                                defaultValue={paymentDueDate}
                                onChange={handleDateChange}
                            /> */}
                            {/* <CustomDateTimePicker
                                selectedDate={paymentDueDate ? dayjs(paymentDueDate) : null}
                                onDateChange={handleDateChange}
                                showTimeSelect={false}
                            /> */}
                        </Form.Item>
                        <Form.Item
                            label={auction.auctionType === "rent" ? "Vuokrahinta" : "Kauppahinta"}
                            name="salePrice"
                            rules={[{ required: true, message: "Kauppahinta on pakollinen" }]}
                        >
                            <InputNumber className="w-36" addonAfter="€" />
                        </Form.Item>
                        <Form.Item
                            label="Tilinumero"
                            name="accountNumber"
                            rules={[{ required: true, message: "Tilinumero on pakollinen" }]}
                        >
                            <Input className="w-48" placeholder="Tilinumero" />
                        </Form.Item>
                        <Form.Item
                            label="Tilinhaltija"
                            name="accountOwner"
                            rules={[{ required: true, message: "Tilinhaltija on pakollinen" }]}
                        >
                            <Input className="w-56" placeholder="Tilinhaltija" />
                        </Form.Item>
                    </div>

                    {/* Sellers Section */}
                    <h2>{auction.auctionType === "rent" ? "Vuokranantajan" : "Myyjän / myyjien"} tiedot</h2>

                    <Checkbox
                        id="seller"
                        className="mb-5 select-none"
                        onChange={handleCheckboxChange}
                        checked={isCompany.seller}
                    >
                        {auction.auctionType === "rent" ? "Vuokranantajana" : "Myyjänä"} toimii yritys
                    </Checkbox>

                    <Form.List name="sellers">
                        {(fields, { add, remove }) => (
                            <ContractPersonForm
                                fields={fields}
                                add={() => onAddPerson("sellers")}
                                remove={remove}
                                label="Myyjä"
                                isCompany={isCompany.seller}
                                auctionType={auction.auctionType}
                                form={form}
                            />
                        )}
                    </Form.List>

                    {/* Buyers Section */}
                    <h2>{auction.auctionType === "rent" ? "Vuokraajan" : "Ostajan / ostajien"}</h2>

                    <Checkbox
                        id="buyer"
                        className="mb-5 select-none"
                        onChange={handleCheckboxChange}
                        checked={isCompany.buyer}
                    >
                        {auction.auctionType === "rent" ? "Vuokraajana" : "Ostajana"} toimii yritys
                    </Checkbox>

                    <Form.List name="buyers">
                        {(fields, { add, remove }) => (
                            <ContractPersonForm
                                fields={fields}
                                add={() => onAddPerson("buyers")}
                                remove={remove}
                                label="Ostaja"
                                isCompany={isCompany.buyer}
                                auctionType={auction.auctionType}
                                form={form}
                            />
                        )}
                    </Form.List>

                    <Form.Item
                        name="info"
                        label={
                            <>
                                Lisätiedot
                                <Tooltip title="Jos osakkeeseen kohdistuu yhtiölainaa voit mainita siitä tässä. Lisää silloin myös velaton kauppahinta (yhtiölaina + kauppahinta).">
                                    <div className="text-lg">
                                        <QuestionCircleFilled className="text-blue-700 ml-2" />
                                    </div>
                                </Tooltip>
                            </>
                        }
                    >
                        <Input.TextArea placeholder="Lisätiedot" rows={6} />
                    </Form.Item>

                    {auction.auctionType !== "rent" && (
                        <div className="text-sm mb-5">
                            Myyjä vakuuttaa, että kaupan kohteen osake on siirtokelpoinen ja että yhtiövastikkeet ja
                            muut velvoitteet on maksettu kauppaa edeltävältä ajalta. Ostajalle siirtyy tämän
                            kauppakirjan allekirjoituksin omistusoikeus myytyihin osakkeisiin sekä käyttöoikeus
                            huoneistoon siihen liittyvine tiloineen, irtaimistoineen ja tarpeistoineen yhtiöjärjestyksen
                            mukaisesti. Ostaja vastaa varainsiirtoverosta 1,5% sekä mahdollisista rekisteröintimaksuista
                            isännöitsijälle. Vastikemaksuvelvollisuus siirtyy ostajalle tämän kauppakirjan
                            allekirjoituspäivästä. Ostajan tulee maksaa varainsiirtovero ja lähettää todistus
                            varainsiirtoverosta myyjälle. Isännöitsijälle ostajan tulee lähettää todistus
                            varainsiirtoverosta, kopio kauppakirjasta ja kopio osakekirjasta sekä siirtomerkintäsivusta.
                            Ohjeet varainsiirtoveroilmoitukseen löytyy Lomahuuto.fi sivuilta. Myyjä toimittaa
                            osakekirjan ostajalle kirjattuna kirjeenä siirtomerkinnöin varustettuna, kun kauppahinta on
                            kokonaisuudessaan maksettu. Tätä kauppakirjaa on laadittu kaksi saman sanaista kappaletta,
                            toinen ostajalle ja toinen myyjälle.
                        </div>
                    )}
                    {!isClosed && (
                        <>
                            {!auction.contractPaid && (
                                <div className="bg-blue-100 border border-blue-400 text-blue-900 p-3 rounded mb-5">
                                    <p>
                                        Kauppakirjan sähköinen luonti on maksullinen palvelu. Klikkaamalla "Tallenna ja
                                        lähetä sopimus" sinut ohjataan maksupalveluun. Onnistuneen maksun jälkeen
                                        sopimukset lähetetään osapuolille sähköpostin liitteenä.
                                    </p>
                                    <p className="mb-0 text-base">
                                        Tämän palvelun ({product.title}) hinta on <strong>{product.price}&euro;</strong>{" "}
                                        (sis. alv 25,5 %).
                                    </p>
                                </div>
                            )}

                            <Form.Item name="finalize" valuePropName="checked">
                                <Checkbox
                                    onChange={(e) => setCloseContract(e.target.checked)}
                                    className="select-none"
                                    disabled={loading}
                                >
                                    Lähetä sopimus allekirjoitettavaksi
                                </Checkbox>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    {closeContract ? "Tallenna ja lähetä sopimus" : "Tallenna sopimus"}
                                </Button>
                            </Form.Item>

                            {formHasErrors && (
                                <div className="bg-red-100 border border-red-400 text-red-900 p-3 rounded mb-5">
                                    Täytä kaikki vaaditut kentät.
                                </div>
                            )}
                        </>
                    )}
                </Form>
            </div>
            {/* Confirmation Modal */}
            <Modal title="Vahvista Sopimus" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>
                    Sinut siirretään nyt maksupalveluun. Onnistuneen maksun jälkeen sopimukset lähetetään kaikille
                    osapuolille sähköpostin liitteenä, eikä sitä voi tämän jälkeen enää muokata.
                </p>
                <p>
                    Tämän palvelun ({product.title}) hinta on <strong>{product.price}&euro;</strong> (sis. alv 25,5 %).
                </p>
                <p>Haluatko jatkaa?</p>
            </Modal>
        </Spin>
    );
};

export default ContractForm;
