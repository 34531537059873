import React, { useState } from "react";
import { Form, Input, Button, Spin, message } from "antd";
import api from "../services/Api";

const ContactPage = () => {
    const [sending, setSending] = useState(false);
    const [form] = Form.useForm();

    const sendMessage = async (formData) => {
        setSending(true);

        try {
            await api.sendContactMessage(formData);
            message.success("Viesti lähetetty onnistuneesti!");
            form.resetFields(); // Clear the form after successful submission
        } catch (error) {
            message.error("Virhe lähetettäessä viestiä, yritä uudelleen.");
        } finally {
            setSending(false);
        }
    };

    const handleFinish = (values) => {
        sendMessage(values); // Send the gathered form data
    };

    return (
        <div className={"container m-auto max-w-screen-2xl md:px-0 text-base mt-5"}>
            <h1>Yhteystiedot</h1>

            <div className="flex">
                <Form form={form} layout="vertical" onFinish={handleFinish} className="md:w-1/2">
                    <Form.Item label="Nimi" name="name" rules={[{ required: true, message: "Anna nimesi" }]}>
                        <Input className="p-2 border border-gray-300 rounded w-full" />
                    </Form.Item>

                    <Form.Item
                        label="Sähköposti"
                        name="email"
                        rules={[
                            { required: true, message: "Anna sähköpostiosoitteesi" },
                            { type: "email", message: "Syötä kelvollinen sähköpostiosoite" },
                        ]}
                    >
                        <Input type="email" className="p-2 border border-gray-300 rounded w-full" />
                    </Form.Item>

                    <Form.Item label="Puhelinnumero" name="phonenumber"
                        rules={[
                            { required: true, message: "Anna puhelinnumerosi" },
                        ]}
                    >
                        <Input type="tel" className="p-2 border border-gray-300 rounded w-full" />
                    </Form.Item>

                    <Form.Item label="Viesti" name="message" rules={[{ required: true, message: "Kirjoita viesti" }]}>
                        <Input.TextArea rows={7} className="p-2 border border-gray-300 rounded w-full" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={sending}>
                            {sending ? <Spin /> : "Lähetä"}
                        </Button>
                    </Form.Item>
                </Form>
                <div className="ml-10">
                    <p className="mb-0">Sähköposti:</p>
                    <p><a href="mailto:asiakaspalvelu@lomahuuto.fi" className="text-blue-700 underline">asiakaspalvelu@lomahuuto.fi</a></p>

                    <p className="mb-0">Puhelinnumero:</p>
                    <p><a href="tel:044 939 2301" className="text-blue-700 underline">044 939 2301</a></p>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
