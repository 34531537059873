import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Image, Spin } from "antd";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";

import api from "../services/Api";
import { Helmet } from "react-helmet-async";

const URL = process.env.REACT_APP_API_URL;

const BlogPost = () => {
    const [post, setPost] = useState(null);
    const [posts, setPosts] = useState(null);
    const [loading, setLoading] = useState(true);
    const slug = useParams().slug;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchPost = async () => {
            setLoading(true);

            try {
                const response = await api.getBlogPostBySlug(slug);
                setPost(response);
            } catch (error) {
                console.error("Error fetching blog post:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPost();
    }, [slug]);

    useEffect(() => {
        const fetchPosts = async () => {
            setLoading(true);

            try {
                const response = await api.getBlogPosts();
                setPosts(response);
            } catch (error) {
                console.error("Error fetching blog posts:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    if (loading) {
        return (
            <div className="flex justify-center items-center absolute w-full bg-white bg-opacity-25 h-full z-20">
                <Spin size="large" />
            </div>
        );
    }

    if (!post) {
        return <div>Artikkelia ei löytynyt.</div>;
    }

    const getFirstParagraph = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        const firstParagraph = doc.querySelector("p");

        return firstParagraph ? firstParagraph.textContent : "";
    };

    return (
        <>
            <Helmet>
                <title>{post.title} - Lomahuuto.fi</title>
                <meta name="description" content={getFirstParagraph(post.content)} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={post.title} />
                <meta property="og:description" content={getFirstParagraph(post.content)} />
                <meta property="og:image" content={`${URL}/uploads/blog/${post.imageFilename}`} />
            </Helmet>
            <div className="container m-auto max-w-screen-2xl md:px-0 text-base my-5">
                <div className="mb-2 pb-2 border-b border-b-gray-200 text-primary">
                    <DoubleLeftOutlined />{" "}
                    <Link to="/" className="hover:underline">
                        Etusivulle
                    </Link>
                </div>
                <div className="md:flex gap-5">
                    <div key={post.id} className="mb-5 md:w-3/4">
                        <h2 className="text-2xl font-medium text-primary hover:underline">{post.title}</h2>
                        <Image src={`${URL}/uploads/blog/${post.imageFilename}`} alt={post.title} preview={false} />
                        <p className="text-lg text-black" dangerouslySetInnerHTML={{ __html: post.content }}></p>
                    </div>
                    <div className="md:w-1/4">
                        <h3 className="text-xl font-medium text-primary uppercase">Kaikki artikkelit</h3>
                        {posts &&
                            posts.map((post) => (
                                <div key={post.id} className="mb-5">
                                    <Link to={`/artikkelit/${post.slug}`}>
                                        <p className="text-xl font-medium text-blue-700 hover:underline">
                                            {post.title}
                                        </p>
                                        <p className="text-sm text-primary text-right">
                                            <DoubleRightOutlined className="mr-2" />
                                            Lue koko artikkeli
                                        </p>
                                    </Link>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogPost;
