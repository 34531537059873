import React, { useState, useContext, useEffect } from "react";

import ProfileEditModal from "../../components/modals/ProfileEditModal";
import PasswordChangeModal from "../../components/modals/PasswordChangeModal";

import { Button, Spin } from "antd";
import { CalendarOutlined, MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";

import { AuctionTypeLabels } from "../../constants/AuctionTypes";
import { AuthContext } from "../../context/AuthContext";

import api from "../../services/Api";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Profile = () => {
    const { user, hasRole } = useContext(AuthContext);
    const [isProfileEditModalOpen, setIsProfileEditModalOpen] = useState(false);
    const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);
    const [auctionOffers, setAuctionOffers] = useState([]);
    const [auctionOffersLoading, setAuctionOffersLoading] = useState(false);
    const [approvingOffer, setApprovingOffer] = useState(0);

    const currentUrl = `${window.location.origin}${window.location.pathname}`;

    const fetchAuctionOffers = async () => {
        setAuctionOffersLoading(true);

        try {
            const response = await api.getAuctionOffers();
            setAuctionOffers(response);
        } catch (error) {
            //console.error(error);
        } finally {
            setAuctionOffersLoading(false);
        }
    };

    useEffect(() => {
        fetchAuctionOffers();
    }, []);

    const showProfileEditModal = () => {
        setIsProfileEditModalOpen(true);
    };

    const closeProfileEditModal = () => {
        setIsProfileEditModalOpen(false);
    };

    const showPasswordChangeModal = () => {
        setIsPasswordChangeModalOpen(true);
    };

    const closePasswordChangeModal = () => {
        setIsPasswordChangeModalOpen(false);
    };

    const handleAcceptOffer = async (offerId) => {
        setApprovingOffer(offerId);

        try {
            await api.acceptOffer(offerId);
            fetchAuctionOffers();
        } catch (error) {
            console.error(error);
        } finally {
            setApprovingOffer(0);
        }
    };

    const handleRejectOffer = async (offerId) => {
        setApprovingOffer(offerId);

        try {
            await api.rejectOffer(offerId);
            fetchAuctionOffers();
        } catch (error) {
            console.error(error);
        } finally {
            setApprovingOffer(0);
        }
    };

    const handleAuthentication = () => {
        window.location.href = user.authUrl;
    };

    const sendEmail = async () => {
        try {
            await api.sendEmail();
        } catch (error) {
            console.error(error);
        }
    };

    const handleConfirmPayment = async (offerId) => {
        try {
            await api.confirmPayment(offerId);
            fetchAuctionOffers();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Oma tili - Lomahuuto.fi</title>
                <meta
                    name="description"
                    content="Lomahuuto.fi tarjoaa helppokäyttöisen ja keskitetyn markkinapaikan lomaosakkeille."
                />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Lomahuuto.fi" />
                <meta
                    property="og:description"
                    content="Lomahuuto.fi tarjoaa helppokäyttöisen ja keskitetyn markkinapaikan lomaosakkeille."
                />
            </Helmet>
            <ProfileEditModal user={user.uuid} open={isProfileEditModalOpen} onClose={closeProfileEditModal} />
            <PasswordChangeModal open={isPasswordChangeModalOpen} onClose={closePasswordChangeModal} />

            {/* {user.verified === false && ( */}
            {hasRole(user.roles, "ROLE_GOD") && (
                <div className="bg-yellow-200 p-5 mb-5 flex items-center justify-between">
                    <div>
                        <div className="text-lg font-bold">Tiliäsi ei ole vielä vahvistettu</div>
                        <div className="text-base">
                            Jotta voit tehdä tarjouksia tai jättää ilmoituksia, tulee sinun tunnistautua
                            pankkitunnuksilla.
                        </div>
                    </div>
                    <Button type="primary" size="large" onClick={handleAuthentication}>
                        Tunnistaudu
                    </Button>
                </div>
            )}

            <div className="md:flex justify-between items-start">
                <div>
                    <h1 className="text-2xl mb-0 font-normal">{user.name}</h1>
                    <h2 className="mb-5 font-normal">{user.email}</h2>
                </div>
                <div className="md:space-x-2 md:text-right flex justify-between">
                    {/* {hasRole(user.roles, "ROLE_GOD") && (
                        <Button type="primary" onClick={sendEmail}>
                            Lähetä sähköposti
                        </Button>
                    )} */}

                    <Button type="primary" onClick={showProfileEditModal}>
                        Muokkaa profiilia
                    </Button>
                    <Button type="primary" onClick={showPasswordChangeModal}>
                        Vaihda salasana
                    </Button>
                </div>
            </div>
            <hr className="md:border-bottom md:border-gray-400" />
            <div className="mt-5">
                {auctionOffersLoading ? (
                    <Spin />
                ) : (
                    <>
                        {auctionOffers.pendingOffers?.length > 0 && (
                            <>
                                <h2 className="text-xl font-bold">Avoimet tarjoukset</h2>

                                <div className="text-lg grid grid-cols-2 gap-5">
                                    {auctionOffers.pendingOffers.map((offer) => (
                                        <div key={offer.auction.uuid} className="shadow-md p-3 relative">
                                            {approvingOffer === offer.id && (
                                                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-5 flex items-center justify-center">
                                                    <Spin />
                                                </div>
                                            )}
                                            <div className="text-lg font-bold uppercase -m-3 bg-gray-200 p-3 mb-3">
                                                {AuctionTypeLabels[offer.auction.type]}
                                            </div>
                                            <div className="space-y-2">
                                                <div>
                                                    <div className="text-sm">Kohde</div>#{offer.auction.number}{" "}
                                                    {offer.auction.title}
                                                </div>
                                                <div>
                                                    <div className="text-sm">Tarjous annettu</div>
                                                    {offer.createdAt}
                                                </div>
                                                <div>
                                                    <div className="text-sm">
                                                        {offer.auction.type === "auction"
                                                            ? "Korkein tarjous"
                                                            : "Tarjous"}
                                                    </div>
                                                    {offer.amount} &euro;
                                                </div>
                                            </div>
                                            <div className="flex justify-between mt-5">
                                                <Button
                                                    type="primary"
                                                    danger
                                                    onClick={() => handleRejectOffer(offer.id)}
                                                >
                                                    Hylkää
                                                </Button>
                                                <Button type="primary" onClick={() => handleAcceptOffer(offer.id)}>
                                                    Hyväksy
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {auctionOffers.pendingDeals?.length > 0 && (
                            <>
                                <h2 className="text-xl font-bold mt-5">Hyväksytyt tarjoukset</h2>

                                <div className="text-lg grid md:grid-cols-2 grid-cols-1 gap-5">
                                    {auctionOffers.pendingDeals.map((offer) => (
                                        <div key={offer.auction.uuid} className="shadow-md p-3">
                                            <div className="text-lg font-bold uppercase -m-3 bg-gray-200 p-3 mb-3">
                                                {AuctionTypeLabels[offer.auction.type]}
                                            </div>
                                            <div className="space-y-2">
                                                <div>
                                                    <div className="text-sm">Kohde</div>#{offer.auction.number}{" "}
                                                    {offer.auction.title}
                                                </div>
                                                <div>
                                                    <div className="text-sm">Tarjouksen tiedot</div>
                                                    <div className="text-sm">
                                                        <div className="flex items-center text-lg">
                                                            <UserOutlined className="mr-2" /> {offer.buyer.name}
                                                        </div>
                                                        <div className="flex items-center">
                                                            <MailOutlined className="mr-2" /> {offer.buyer.email}
                                                        </div>
                                                        <div className="flex items-center">
                                                            <PhoneOutlined className="mr-2" /> {offer.buyer.phone}
                                                        </div>
                                                        <div className="flex items-center">
                                                            <CalendarOutlined className="mr-2" /> {offer.createdAt}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <div className="text-sm">
                                                            {offer.auction.type === "auction"
                                                                ? "Korkein tarjous"
                                                                : "Tarjous"}
                                                        </div>
                                                        {offer.amount}
                                                    </div>

                                                    <Link to={`/oma-sivu/sopimus/${offer.auction.uuid}`}>
                                                        <Button type="primary" className="ml-5">
                                                            Luo{" "}
                                                            {offer.auction.type === "rent"
                                                                ? "vuokrasopimus"
                                                                : "kauppakirja"}
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {auctionOffers.completedSells?.length > 0 && (
                            <>
                                <h2 className="text-xl font-bold mt-5">Myydyt / vuokratut kohteet</h2>

                                <div className="text-sm grid md:grid-cols-3 grid-cols-1 gap-5">
                                    {auctionOffers.completedSells.map((offer) => (
                                        <div key={offer.auction.uuid} className="shadow-md p-3">
                                            <div className="text-base font-bold uppercase -m-3 bg-gray-200 p-3 mb-3">
                                                {AuctionTypeLabels[offer.auction.type]}
                                            </div>
                                            <div className="space-y-2">
                                                <div>
                                                    <div className="text-sm">Kohde</div>#{offer.auction.number}{" "}
                                                    {offer.auction.title}
                                                </div>
                                                <div>
                                                    <div className="text-sm">Tarjous annettu</div>
                                                    {offer.createdAt}
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <div className="text-sm">
                                                            {offer.auction.type === "auction"
                                                                ? "Korkein tarjous"
                                                                : "Tarjous"}
                                                        </div>
                                                        {offer.amount}
                                                    </div>
                                                    <div className="flex justify-between mt-5">
                                                        <Button
                                                            type="primary"
                                                            onClick={() => handleConfirmPayment(offer.id)}
                                                        >
                                                            Kuittaa maksu saaduksi
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {auctionOffers.acceptedBids?.length > 0 && (
                            <>
                                <h2 className="text-xl font-bold mt-5">Ostetut / vuokratut kohteet</h2>

                                <div className="text-sm grid md:grid-cols-3 grid-cols-1 gap-5">
                                    {auctionOffers.acceptedBids.map((offer) => (
                                        <div key={offer.auction.uuid} className="shadow-md p-3">
                                            <div className="text-base font-bold uppercase -m-3 bg-gray-200 p-3 mb-3">
                                                {AuctionTypeLabels[offer.auction.type]}
                                            </div>
                                            <div className="space-y-2">
                                                <div>
                                                    <div className="text-sm">Kohde</div>#{offer.auction.number}{" "}
                                                    {offer.auction.title}
                                                </div>
                                                <div>
                                                    <div className="text-sm">Tarjous annettu</div>
                                                    {offer.createdAt}
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <div className="text-sm">Tarjouksesi</div>
                                                        {offer.amount}
                                                    </div>
                                                    <div className="flex justify-between mt-5">
                                                        <Button
                                                            type="primary"
                                                            onClick={() => handleConfirmPayment(offer.id)}
                                                        >
                                                            Kuittaa maksu suoritetuksi
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default Profile;
