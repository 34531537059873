import React, { useEffect, useState } from "react";
import { Form, Input, Button, Spin, message, Table } from "antd";
import api from "../../services/Api";
import { Link } from "react-router-dom";
import { DeleteFilled } from "@ant-design/icons";

const Blog = () => {
    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                setLoading(true);
                const response = await api.getAdminBlogPosts();
                setPosts(response);
            } catch (error) {
                message.error("Virhe ladatessa artikkeleja, yritä uudelleen.");
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    const handleDeletePost = async (postId) => {
        try {
            await api.deleteBlogPost(postId);
            setPosts(posts.filter((post) => post.id !== postId));
        } catch (error) {
            message.error("Virhe poistettaessa artikkelia, yritä uudelleen.");
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: "Otsikko",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Julkaisu",
            dataIndex: "publishedAt",
            key: "publishedAt",
            render: (date) => <span>{new Date(date).toLocaleString('fi-FI')}</span>,
        },
        {
            title: "Toiminnot",
            key: "actions",
            render: (text, record) => (
                <div className="flex items-center">
                    <Link to={`/hallinta/artikkelit/${record.id}`} className="flex items-center bg-primary text-white rounded px-2 py-1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-4 mr-2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                            />
                        </svg>
                        Muokkaa
                    </Link>
                    <div onClick={() => handleDeletePost(record.id)} className="flex items-center bg-red-700 text-white rounded px-2 py-1 ml-5 cursor-pointer">
                        <DeleteFilled className="mr-2" /> Poista
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className={"container m-auto max-w-screen-2xl md:px-0 text-base mt-5"}>
            <h1>Artikkelit</h1>

            <Link to="/hallinta/artikkelit/0">
                <Button type="primary" className="mb-5">
                    Lisää artikkeli
                </Button>
            </Link>

            {loading ? <Spin /> : <Table dataSource={posts} columns={columns} rowKey="id" />}
        </div>
    );
};

export default Blog;
