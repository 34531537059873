import React, { useEffect, useState } from "react";
import AuctionList from "../components/common/AuctionList";
import { Link } from "react-router-dom";
import AuctionFeaturedList from "../components/common/AuctionFeaturedList";
import { DoubleRightOutlined } from "@ant-design/icons";
import api from "../services/Api";
import BlogList from "./BlogList";
import { Helmet } from "react-helmet-async";

const Home = () => {
    const [auctionCount, setAuctionCount] = useState([]);

    const currentUrl = `${window.location.origin}${window.location.pathname}`;

    useEffect(() => {
        fetchAuctionCount();
    }, []);

    const fetchAuctionCount = async () => {
        try {
            const response = await api.auctionsCountByType();
            setAuctionCount(response);
        } catch (error) {
            console.error("Error fetching auction count:", error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Lomahuuto.fi</title>
                <meta
                    name="description"
                    content="Lomahuuto.fi tarjoaa helppokäyttöisen ja keskitetyn markkinapaikan lomaosakkeille."
                />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Lomahuuto.fi" />
                <meta
                    property="og:description"
                    content="Lomahuuto.fi tarjoaa helppokäyttöisen ja keskitetyn markkinapaikan lomaosakkeille."
                />
            </Helmet>
            <div className="bg-gray-50 w-full p-5 2xl:p-0">
                <section className="container max-w-screen-2xl m-auto pt-10">
                    <h3 className="text-xl md:text-2xl mb-5 font-medium uppercase text-primary">
                        Lomahuuto.fi kuukauden valinnat
                    </h3>
                    <AuctionFeaturedList />
                </section>
                <section className="container max-w-screen-2xl m-auto pt-10">
                    <div className="flex justify-between items-center">
                        <Link to="/kohteet?t=auction" className="w-full">
                            <div className="flex justify-between items-center mb-5">
                                <h2 className="text-xl md:text-3xl mb-0 font-medium uppercase text-primary hover:underline">
                                    Huutokauppakohteet ({auctionCount.auction ? auctionCount.auction : 0})
                                </h2>
                                <div className="text-primary text-lg flex items-center transition-all px-2 rounded hover:bg-primary hover:text-white">
                                    <span className="hidden md:block">Katso kaikki</span>
                                    <DoubleRightOutlined className="ml-2" />
                                </div>
                            </div>
                        </Link>
                    </div>

                    <AuctionList filters={{ types: ["auction"] }} limit={4} />

                    <div className="text-center mb-10 md:hidden">
                        <Link
                            to="/kohteet?t=auction"
                            className="w-full bg-primary rounded text-white px-4 py-2 text-lg"
                        >
                            Katso kaikki huutokauppakohteet
                            <DoubleRightOutlined className="ml-2" />
                        </Link>
                    </div>

                    <div className="flex justify-between items-center md:mt-5">
                        <Link to="/kohteet?t=sale" className="w-full">
                            <div className="flex justify-between items-center mb-5">
                                <h2 className="text-xl md:text-3xl mb-0 font-medium uppercase text-primary hover:underline">
                                    Myytävät kohteet ({auctionCount.sale ? auctionCount.sale : 0})
                                </h2>
                                <div className="text-primary text-lg flex items-center transition-all px-2 rounded hover:bg-primary hover:text-white">
                                    <span className="hidden md:block">Katso kaikki</span>
                                    <DoubleRightOutlined className="ml-2" />
                                </div>
                            </div>
                        </Link>
                    </div>

                    <AuctionList filters={{ types: ["sale"] }} limit={4} />

                    <div className="text-center mb-10 md:hidden">
                        <Link
                            to="/kohteet?t=auction"
                            className="w-full bg-primary rounded text-white px-4 py-2 text-lg"
                        >
                            Katso kaikki myytävät kohteet
                            <DoubleRightOutlined className="ml-2" />
                        </Link>
                    </div>

                    <div className="flex justify-between items-center md:mt-5">
                        <Link to="/kohteet?t=rent" className="w-full">
                            <div className="flex justify-between items-center mb-5">
                                <h2 className="text-xl md:text-3xl mb-0 font-medium uppercase text-primary hover:underline">
                                    Vuokrattavat kohteet ({auctionCount.rent ? auctionCount.rent : 0})
                                </h2>
                                <div className="text-primary text-lg flex items-center transition-all px-2 rounded hover:bg-primary hover:text-white">
                                    <span className="hidden md:block">Katso kaikki</span>
                                    <DoubleRightOutlined className="ml-2" />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <AuctionList filters={{ types: ["rent"] }} limit={4} />
                    <div className="text-center mb-10 md:hidden">
                        <Link
                            to="/kohteet?t=auction"
                            className="w-full bg-primary rounded text-white px-4 py-2 text-lg"
                        >
                            Katso kaikki vuokrakohteet
                            <DoubleRightOutlined className="ml-2" />
                        </Link>
                    </div>
                    <h2 className="text-xl md:text-3xl font-medium uppercase text-primary mb-5 md:mt-5">Artikkelit</h2>
                    <BlogList />
                </section>
            </div>
        </>
    );
};

export default Home;
