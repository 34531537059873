import React, { useEffect, useState } from "react";
import { Form, Input, Button, Spin, message, Tooltip, Upload } from "antd";
import { Link, useParams } from "react-router-dom";
import { UploadOutlined, InfoCircleFilled } from "@ant-design/icons";
import CKEditorWrapper from "../../components/common/CKEditorWrapper";
import CustomDateTimePicker from "../../components/common/CustomDateTimePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import api from "../../services/Api";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Helsinki");

const URL = process.env.REACT_APP_API_URL;

const BlogEdit = () => {
    const [loading, setLoading] = useState(false);

    const [post, setPost] = useState({
        id: 0,
        title: "",
        content: "",
        imageFilename: "",
        publishedAt: null,
    });

    const [form] = Form.useForm();
    const postId = useParams().postId;
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        const fetchPost = async (postId) => {
            if (postId === '0') return;

            try {
                setLoading(true);
                const response = await api.getBlogPost(postId);
                setPost(response);

                form.setFieldsValue({
                    id: response.id,
                    title: response.title,
                    content: response.content,
                    publishedAt: response.publishedAt ? dayjs(response.publishedAt).toDate() : null,
                    imageFilename: response.imageFilename,
                });

                if (response.imageFilename) {
                    setFileList([
                        {
                            uid: "-1",
                            name: "Uploaded Image",
                            status: "done",
                            url: response.imageFilename,
                        },
                    ]);
                }
            } catch (error) {
                message.error("Virhe ladatessa artikkelia, yritä uudelleen.");
            } finally {
                setLoading(false);
            }
        };

        fetchPost(postId);
    }, [postId]);

    const handleImageUpload = async (file) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("image", file);
            formData.append("filename", file.filename);

            const response = await api.uploadBlogImage(formData);
            setPost((prevPost) => ({ ...prevPost, imageFilename: response.filename }));
            form.setFieldsValue({ imageFilename: response.filename });
            message.success("Kuva ladattu onnistuneesti.");

            // Update fileList for preview
            setFileList([
                {
                    uid: "-1",
                    name: file.name,
                    status: "done",
                    url: response.url,
                },
            ]);
        } catch (error) {
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleUpdatePost = async (values) => {
        try {
            setLoading(true);
            await api.updateBlogPost(postId, values);
            message.success("Artikkeli päivitetty onnistuneesti.");
        } catch (error) {
            message.error("Virhe päivitettäessä artikkelia, yritä uudelleen.");
        } finally {
            setLoading(false);
        }
    };

    const onFinish = (values) => {
        console.log(values);
        const updatedPost = {
            ...post,
            ...values,
            publishedAt: values.publishedAt
                ? dayjs(values.publishedAt).toISOString() // Convert date to ISO format
                : null,
        };
        handleUpdatePost(updatedPost);
    };

    const onImageChange = ({ file }) => {
        handleImageUpload(file);
    };

    return (
        <Spin spinning={loading}>

            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    id: post?.id,
                    title: post?.title,
                    content: post?.content,
                    publishedAt: post?.publishedAt ? dayjs(post.publishedAt).toDate() : null,
                    imageFilename: post?.imageFilename,
                }}
            >
                <Link to={`/hallinta/artikkelit`} className="mb-2 block">
                    <Button type="primary">Takaisin</Button>
                </Link>
                {post?.id ? (
                    <>
                        <h1>Muokkaa artikkelia</h1>
                    </>
                ) : (
                    <h1>Uusi artikkeli</h1>
                )}
                <Form.Item label="ID" hidden>
                    <Input value={postId} />
                </Form.Item>
                <Form.Item label="Otsikko" name="title" rules={[{ required: true, message: "Otsikko vaaditaan." }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Sisältö" name="content">
                    <CKEditorWrapper
                        content={post?.content ? post.content : ""}
                        setContent={(content) => {
                            setPost((prevPost) => ({ ...prevPost, content: content }));
                            form.setFieldsValue({ content }); // Use setFieldsValue to update form state
                        }}
                    />
                </Form.Item>
                <Form.Item label="Artikkelin kuva" name="imageFilename">
                    <Upload
                        accept="image/*"
                        maxCount={1}
                        fileList={fileList}
                        showUploadList={false}
                        beforeUpload={() => false} // Prevent automatic upload
                        onChange={onImageChange}
                        listType="picture"
                    >
                        <Button icon={<UploadOutlined />}>Lataa kuva</Button>
                    </Upload>
                    {post?.imageFilename && (
                        <div className="mt-2">
                            <img
                                src={`${URL}/uploads/blog/${post.imageFilename}`}
                                alt="Artikkelin kuva"
                                style={{ maxWidth: "100%" }}
                            />
                        </div>
                    )}
                </Form.Item>
                <Form.Item
                    label={
                        <>
                            Julkaistaan{" "}
                            <Tooltip title="Jätä tyhjäksi, jos julkaistaan heti">
                                <InfoCircleFilled className="ml-2 text-blue-700" style={{ fontSize: "16px" }} />
                            </Tooltip>
                        </>
                    }
                    name="publishedAt"
                >
                    <CustomDateTimePicker
                        selectedDate={post?.publishedAt ? dayjs(post.publishedAt).toDate() : null}
                        onDateChange={(date) => {
                            setPost((prevPost) => ({ ...prevPost, publishedAt: date }));
                            form.setFieldsValue({ publishedAt: date });
                        }}
                        showTimeSelect={true}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Tallenna
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default BlogEdit;
