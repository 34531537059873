import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Spin } from "antd";
import api from "../services/Api";
import backgroundImage from "../assets/images/lomahuuto-hero-1.webp";

const FaqPage = () => {
    const [loading, setLoading] = useState(false);
    const { page } = useParams();
    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");
    const navigate = useNavigate();

    const faqItems = [
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                    />
                </svg>
            ),
            title: "Käyttöehdot",
            page: "kayttoehdot",
            setting: "terms_of_use",
        },
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                    />
                </svg>
            ),
            title: "Myyntiehdot",
            page: "myyntiehdot",
            setting: "sale_terms",
        },
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                    />
                </svg>
            ),
            title: "Vuokrauksen ehdot",
            page: "vuokrauksen-ehdot",
            setting: "rental_terms",
        },
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                </svg>
            ),
            title: "Hinnasto",
            page: "hinnasto",
            setting: "price_list",
        },
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                    />
                </svg>
            ),
            title: "Huutokauppailmoituksen luonti",
            page: "huutokauppailmoituksen-luonti",
            setting: "help_create_auction",
        },
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                    />
                </svg>
            ),
            title: "Myynti-ilmoituksen luonti",
            page: "myynti-ilmoituksen-luonti",
            setting: "help_create_sale",
        },
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                    />
                </svg>
            ),
            title: "Vuokrailmoituksen luonti",
            page: "vuokrailmoituksen-luonti",
            setting: "help_create_rent",
        },
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                    />
                </svg>
            ),
            title: "Ohjeita ostajalle",
            page: "ohjeita-ostajalle",
            setting: "instructions_to_buyer",
        },
    ];

    const fetchPage = async (setting) => {
        setLoading(true);

        try {
            const response = await api.getSetting(setting);
            setContent(response.value);
            setTitle(faqItems.find((item) => item.page === page).title);
        } catch (error) {
            console.error("Error fetching page:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (page) {
            const setting = faqItems.find((item) => item.page === page).setting;
            fetchPage(setting);
        }
    }, [page]);

    const headingStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center 70%",
    };

    const handlePageLoad = (page) => {
        const setting = faqItems.find((item) => item.page === page).setting;
        navigate(`/ehdot-ja-ohjeet/${page}`);
        fetchPage(setting);
    };

    return (
        <div className="w-full">
            <div className="w-full h-60 mb-10" style={headingStyle}>
                <div className="flex items-center justify-center w-full h-full bg-white bg-opacity-50">
                    <h1 className="text-primary text-4xl">Ehdot ja ohjeet</h1>
                </div>
            </div>
            {page ? (
                <div className="container m-auto max-w-screen-2xl md:px-0 text-base my-5">
                    {loading ? (
                        <Spin />
                    ) : (
                        <div className="block md:flex gap-10">
                            <div className="md:w-3/4">
                                <h1>{title}</h1>
                                <div dangerouslySetInnerHTML={{ __html: content }} />
                            </div>
                            <div className="flex flex-col md:w-1/4">
                                <FaqItemLinks
                                    faqItems={faqItems}
                                    handlePageLoad={handlePageLoad}
                                    className="cursor-pointer bg-primary-100 bg-opacity-20 p-5 rounded-lg flex items-center gap-5 mb-5 font-normal"
                                    currentPage={page}
                                />
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="container m-auto max-w-screen-2xl md:px-0 text-base my-5">
                    <div className="md:grid md:grid-cols-2 md:gap-5 mt-5 xl:grid-cols-4">
                        <FaqItemLinks
                            faqItems={faqItems}
                            handlePageLoad={handlePageLoad}
                            className="cursor-pointer bg-primary-100 bg-opacity-20 md:p-10 p-5 rounded-lg flex md:flex-col items-center gap-5 mb-5 md:mb-0 font-normal md:font-bold"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default FaqPage;

export const FaqItemLinks = ({ faqItems, handlePageLoad, className, currentPage }) => {
    return (
        <>
            {faqItems.map((item, index) => {
                const itemClassName = `${className} ${currentPage === item.page ? 'border-4 border-green-400' : ''}`;

                return (
                    <div key={index} onClick={() => handlePageLoad(item.page)} className={itemClassName}>
                        <div className="bg-primary p-3 rounded-full text-white">{item.icon}</div>
                        <div className="text-xl">{item.title}</div>
                    </div>
                );
            })}
        </>
    );
};
