import { Form, Modal, Radio, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import api from "../../services/Api";

const AuctionFeaturePurchaseModal = ({ isAuctionFeaturePurchaseModalOpen, onClose }) => {
    const { user } = useContext(AuthContext);
    const [userLiveAuctions, setUserLiveAuctions] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [form] = Form.useForm();

    const fetchUserLiveAuctions = async () => {
        try {
            const response = await api.getUserAuctions("published");
            setUserLiveAuctions(response);
        } catch (error) {
            console.error("Error fetching user live auctions:", error);
        }
    };

    const fetchProducts = async () => {
        try {
            const response = await api.getProducts("showcase");
            setProducts(response);
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    useEffect(() => {
        if (isAuctionFeaturePurchaseModalOpen) {
            fetchUserLiveAuctions();
            fetchProducts();
        }
    }, [isAuctionFeaturePurchaseModalOpen]);

    const handleSubmit = async (values) => {
        const payload = {
            productCode: selectedProduct.code,
            price: selectedProduct.price,
            redirectUrl: `${window.location.origin}/`,
            auctionUuid: values.selectedAuction,
        };

        try {
            const response = await api.createPayment(payload);
            
            document.location.href = response.href;
        } catch (error) {
            console.error("Error during payment processing: ", error);
        }
    };

    return (
        <Modal
            open={isAuctionFeaturePurchaseModalOpen}
            onCancel={onClose}
            onOk={() => form.submit()}
        >
            <h2>Nosta ilmoituksesi etusivulle</h2>

            <Form onFinish={handleSubmit} layout="vertical" form={form}>
                <Form.Item
                    name="selectedAuction"
                    label="Valitse ilmoitus"
                    rules={[{ required: true, message: "Valitse ilmoitus" }]}
                >
                    <Select
                        placeholder={<div className="text-gray-500">Valitse ilmoitus</div>}
                        className="w-full mb-3"
                    >
                        {userLiveAuctions.map((auction) => (
                            <Select.Option key={auction.id} value={auction.uuid} disabled={auction.featuredUntil}>
                                #{auction.number} {auction.title}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="selectedProductCode"
                    label="Valitse kesto"
                    rules={[{ required: true, message: "Valitse kesto" }]}
                >
                    <Radio.Group name="auctionType" buttonStyle="solid">
                        {products.map((product) => (
                            <Radio.Button key={product.id} value={product.code} onClick={() => setSelectedProduct(product)}>
                                {product.shortTitle} - {product.price} &euro;
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AuctionFeaturePurchaseModal;
