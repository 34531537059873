import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Spin, Button, Modal, message as antdMessage, Popover } from "antd";
import { HeartOutlined, HeartFilled, ShareAltOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet-async";

import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
} from "react-share";

import AuctionGallery from "../components/common/AuctionPage/AuctionGallery";
import AuctionDetails from "../components/common/AuctionPage/AuctionDetails";
import AuctionPriceInfo from "../components/common/AuctionPage/AuctionPriceInfo";
import AuctionBidInfo from "../components/common/AuctionPage/AuctionBidInfo";
import AuctionChatBox from "../components/common/AuctionPage/AuctionChatBox";

import { AuthContext } from "../context/AuthContext";
import { ModalContext } from "../context/ModalContext";

import api from "../services/Api";

import banner from "../assets/images/banners/banneri-500x955.png";
import banner2 from "../assets/images/banners/banneri-500x955_2.png";

const AuctionPage = () => {
    const { number, slug } = useParams();
    const { isAuthenticated } = useContext(AuthContext);
    const { showLoginModal } = useContext(ModalContext);
    const [auction, setAuction] = useState(null);
    const [loading, setLoading] = useState(true);
    //    const [soundEnabled, setSoundEnabled] = useState(true);
    const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
    const [terms, setTerms] = useState({
        label: "",
        content: "",
    });
    const [favorite, setFavorite] = useState(auction?.isFavorite);
    const [isHovered, setIsHovered] = useState(false);
    let hideTimeout;
    const [randomBanner, setRandomBanner] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const banners = [
            { image: banner, url: "https://www.sunholidays.fi/" },
            { image: banner2, url: "https://vaihtolomat.fi/" },
        ];

        setRandomBanner(banners[Math.floor(Math.random() * banners.length)]);
    }, []);

    const handleMouseEnter = () => {
        if (hideTimeout) {
            clearTimeout(hideTimeout);
        }
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        hideTimeout = setTimeout(() => {
            setIsHovered(false);
        }, 250);
    };

    useEffect(() => {
        const fetchAuction = async () => {
            try {
                const response = await api.getAuction(number);
                setAuction(response);
                setFavorite(response.isFavorite);

                if (!slug) {
                    window.history.replaceState({}, "", `/kohde/${number}/${response.slug}`);
                }

                setLoading(false);
            } catch (error) {
                setLoading(false);
                navigate("/404");
            }
        };

        if (number) {
            fetchAuction();
        }
    }, [number]);

    if (loading) {
        return <Spin />;
    }

    const toggleFavorite = async (e) => {
        e.preventDefault();

        if (isAuthenticated) {
            const response = await api.toggleFavorite(auction.number);

            setFavorite(response.isFavorite);
            antdMessage.success(response.isFavorite ? "Kohde lisätty suosikkeihin" : "Kohde poistettu suosikeista");
        } else {
            showLoginModal();
        }
    };

    const copyUrlToClipboard = () => {
        const url = window.location.href;
        navigator.clipboard.writeText(url).then(() => {
            antdMessage.success("Linkki kopioitu");
        });
    };

    const handleTermsModalOpen = (settingsName) => async () => {
        try {
            const response = await api.getSetting(settingsName);
            setTerms({
                label: response.label,
                content: response.value,
            });
            setIsTermsModalVisible(true);
        } catch (error) {
            console.error("Error fetching terms:", error);
        }
    };

    const auctionUrl = window.location.href;
    const auctionTitle =
        "Tämä upea lomaosake voi olla sinun! " + auction?.property.title + " - " + auction?.apartmentDescription;
    const auctionDescription = auction?.property.title + " / " + auction?.apartmentDescription;

    return (
        <>
            {auction && (
                <>
                    <Helmet>
                        <title>{auction.property.title} - Lomahuuto.fi</title>
                        <meta name="title" content={auction.property.title} />
                        <meta name="description" content={auctionDescription} />
                        <meta property="og:title" content={auction.property.title} />
                        <meta property="og:description" content={auctionDescription} />
                        <meta property="og:image" content={auction.images[0]?.urls.medium} />
                    </Helmet>
                    <div className="container max-w-screen-2xl m-auto text-lg md:p-5 md:px-0">
                        <div className="my-5 md:mt-0">
                            <h1 className="uppercase text-xl md:text-3xl m-0 flex justify-between">
                                <span className="flex-1">{auction.property.title}</span>

                                <div
                                    className="flex items-center relative"
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    {isHovered && (
                                        <div className="flex items-center space-x-2 absolute top-1 right-16 transition-all">
                                            <FacebookShareButton url={auctionUrl} quote={auctionTitle}>
                                                <FacebookIcon size={32} round />
                                            </FacebookShareButton>

                                            <TwitterShareButton url={auctionUrl} title={auctionTitle}>
                                                <TwitterIcon size={32} round />
                                            </TwitterShareButton>

                                            <LinkedinShareButton url={auctionUrl} title={auctionTitle}>
                                                <LinkedinIcon size={32} round />
                                            </LinkedinShareButton>

                                            <WhatsappShareButton url={auctionUrl} title={auctionTitle}>
                                                <WhatsappIcon size={32} round />
                                            </WhatsappShareButton>
                                        </div>
                                    )}
                                    <ShareAltOutlined className="ml-2 mr-5 text-primary" />
                                </div>

                                {isAuthenticated && (
                                    <div className="text-red-500 text-3xl cursor-pointer mr-5" onClick={toggleFavorite}>
                                        {favorite ? <HeartFilled /> : <HeartOutlined />}
                                    </div>
                                )}
                                <span className="text-gray-400 cursor-pointer">
                                    <Popover content="Kopioi linkki sivulle" onClick={copyUrlToClipboard}>
                                        #{auction.number}
                                    </Popover>
                                </span>
                            </h1>
                            <h3 className="text-xl text-gray-600">
                                {auction.apartmentDescription !== '' ? <>{auction.apartmentDescription} &bull; </> : ''} 
                                {auction.property.ownershipType === "week" && "Viikko-osake"}
                                {auction.property.ownershipType === "other" && "Muu omistus"}
                                {auction.property.ownershipType !== "week" &&
                                    auction.property.ownershipType !== "other" &&
                                    auction.property.ownershipType + " osaomistus"}
                            </h3>
                        </div>
                        <div className="block md:flex w-auto">
                            <div className="w-auto md:w-3/4 mr-0 md:mr-5">
                                <AuctionGallery images={auction.images} />
                                <AuctionDetails auction={auction} />

                                <div className="mt-5">
                                    <h3>Kaupan ehdot</h3>
                                    {auction.type === "rent" ? (
                                        <div
                                            className="text-primary cursor-pointer"
                                            onClick={handleTermsModalOpen("rental_terms")}
                                        >
                                            Vuokrausehdot
                                        </div>
                                    ) : (
                                        <div
                                            className="text-primary cursor-pointer"
                                            onClick={handleTermsModalOpen("sale_terms")}
                                        >
                                            Myyntiehdot
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="w-auto md:w-1/4 bg-primary rounded-md p-5 mb-5">
                                <h2 className="text-white uppercase flex justify-between">
                                    {auction.type === "auction" && <>Huutokauppa</>}
                                    {auction.type === "sale" && <>Myynti</>}
                                    {auction.type === "rent" && <>Vuokraus</>}

                                    {/* <Switch
                                checkedChildren={<SoundFilled />}
                                unCheckedChildren={<SoundOutlined />}
                                checked={soundEnabled}
                                onChange={() => setSoundEnabled(!soundEnabled)}
                                /> */}
                                </h2>

                                {auction.status === "sold" ? (
                                    <div className="bg-red-500 text-white p-4 rounded mt-5">
                                        <div className="text-2xl">
                                            Kohde on {auction.type === "rent" ? "vuokrattu" : "myyty"}
                                        </div>
                                        <div className="text-sm mt-2">Kiitos kaikille tarjouksista!</div>
                                    </div>
                                ) : (
                                    <>
                                        <AuctionPriceInfo auction={auction} />
                                        <AuctionBidInfo auction={auction} setAuction={setAuction} />
                                        {!auction.isUserAuction && <AuctionChatBox auction={auction} />}

                                        {!isAuthenticated && (
                                            <div className="mt-5 text-white text-sm">
                                                <div className="mb-2">
                                                    Kirjaudu sisään tehdäksesi tarjouksen tai lähettääksesi viesti
                                                    myyjälle
                                                </div>
                                                <div className="grid grid-cols-2 gap-2">
                                                    <Button
                                                        className="bg-emerald-300"
                                                        size="large"
                                                        onClick={() => showLoginModal("login")}
                                                    >
                                                        Kirjaudu
                                                    </Button>
                                                    <Button size="large" onClick={() => showLoginModal("register")}>
                                                        Rekisteröidy
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}

                                <div className="banner mt-10">
                                    <Link to={randomBanner.url} target="_blank">
                                        <img
                                            src={randomBanner.image}
                                            alt="Banner"
                                            className="w-full object-cover rounded"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <Modal
                            title={terms.label}
                            open={isTermsModalVisible}
                            onOk={() => setIsTermsModalVisible(false)}
                            onCancel={() => setIsTermsModalVisible(false)}
                            okText="Sulje"
                            width={800}
                            footer={
                                <Button type="primary" onClick={() => setIsTermsModalVisible(false)}>
                                    Sulje
                                </Button>
                            }
                        >
                            <div dangerouslySetInnerHTML={{ __html: terms.content }} />
                        </Modal>
                    </div>
                </>
            )}
        </>
    );
};

export default AuctionPage;
