import React, { useState, useEffect } from "react";
import { Form, Input, Button, Checkbox, Tooltip } from "antd";
import { MinusCircleOutlined, PlusOutlined, QuestionCircleFilled } from "@ant-design/icons";

const ContractPersonForm = ({ fields, add, remove, label, initialData, isCompany, auctionType }) => {
    // State to track mandate checkbox state for each person individually
    const [mandateChecked, setMandateChecked] = useState({});

    // Initialize mandate checkbox state if mandate details are pre-filled
    useEffect(() => {
        const initialMandateState = {};
        fields.forEach((field) => {
            const mandateName = field.mandateName;
            const mandatePhonenumber = field.mandatePhonenumber;
            initialMandateState[field.key] = !!(mandateName || mandatePhonenumber);
        });
        setMandateChecked(initialMandateState);
    }, [fields]);

    const handleCheckboxChange = (fieldKey, checked) => {
        setMandateChecked((prev) => ({
            ...prev,
            [fieldKey]: checked,
        }));
    };

    return (
        <>
            {fields.map((field, index) => (
                <div key={`${field.key}-${index}`}>
                    <div className="border-b border-gray-400 mb-1 hidden"></div>
                    {isCompany && (
                        <div className="grid grid-cols-5 gap-x-2">
                            <Form.Item
                                label="Yrityksen nimi"
                                name={[field.name, "companyName"]}
                                rules={[{ required: true, message: "Yrityksen nimi on pakollinen" }]}
                            >
                                <Input placeholder="Yrityksen nimi" />
                            </Form.Item>
                            <Form.Item
                                label="Y-tunnus"
                                name={[field.name, "businessId"]}
                                rules={[{ required: true, message: "Y-tunnus on pakollinen" }]}
                            >
                                <Input placeholder="Y-tunnus" />
                            </Form.Item>
                        </div>
                    )}
                    <div className="font-bold mb-2">{isCompany ? "Yhteyshenkilö" : label + " " + (index + 1)}</div>
                    <div className="grid grid-cols-5 gap-x-2">
                        <Form.Item
                            label="Etunimi"
                            name={[field.name, "firstname"]}
                            rules={[{ required: true, message: "Etunimi on pakollinen" }]}
                        >
                            <Input placeholder="Etunimi" />
                        </Form.Item>
                        <Form.Item
                            label="Sukunimi"
                            name={[field.name, "lastname"]}
                            rules={[{ required: true, message: "Sukunimi on pakollinen" }]}
                        >
                            <Input placeholder="Sukunimi" />
                        </Form.Item>
                        <Form.Item
                            label="Sähköposti"
                            name={[field.name, "email"]}
                            rules={[{ required: true, message: "Sähköposti on pakollinen" }]}
                        >
                            <Input placeholder="Sähköposti" />
                        </Form.Item>
                        <Form.Item
                            label="Puhelinnumero"
                            name={[field.name, "phonenumber"]}
                            rules={[{ required: true, message: "Puhelinnumero on pakollinen" }]}
                        >
                            <Input placeholder="Puhelinnumero" />
                        </Form.Item>
                        <Form.Item
                            label="Osoite"
                            name={[field.name, "address"]}
                            rules={[{ required: true, message: "Osoite on pakollinen" }]}
                        >
                            <Input placeholder="Osoite" />
                        </Form.Item>
                        <Form.Item
                            label="Postinumero"
                            name={[field.name, "zipcode"]}
                            rules={[{ required: true, message: "Postinumero on pakollinen" }]}
                        >
                            <Input placeholder="Postinumero" />
                        </Form.Item>
                        <Form.Item
                            label="Kaupunki"
                            name={[field.name, "city"]}
                            rules={[{ required: true, message: "Kaupunki on pakollinen" }]}
                        >
                            <Input placeholder="Kaupunki" />
                        </Form.Item>
                        <Form.Item
                            label="Maa"
                            name={[field.name, "country"]}
                            rules={[{ required: true, message: "Maa on pakollinen" }]}
                        >
                            <Input placeholder="Maa" />
                        </Form.Item>
                        {!isCompany && (
                            <Form.Item
                                label="Henkilötunnus"
                                name={[field.name, "ssn"]}
                                rules={[{ required: true, message: "Henkilötunnus on pakollinen" }]}
                            >
                                <Input placeholder="Henkilötunnus" />
                            </Form.Item>
                        )}
                        {fields.length > 1 && (
                            <div className="flex justify-end">
                                <MinusCircleOutlined
                                    className="text-red-700 text-lg"
                                    onClick={() => remove(field.name)}
                                />
                            </div>
                        )}
                    </div>

                    {/* Checkbox for mandate details */}
                    <Form.Item>
                        <Checkbox
                            checked={mandateChecked[field.key]}
                            onChange={(e) => handleCheckboxChange(field.key, e.target.checked)}
                            className="select-none flex items-center"
                        >
                            Allekirjoitetaan valtakirjalla
                            <Tooltip title="Muista toimittaa allekirjoitettu valtakirja toiselle osapuolelle.">
                                <div className="text-lg inline">
                                    <QuestionCircleFilled className="text-blue-700 ml-2" />
                                </div>
                            </Tooltip>
                        </Checkbox>
                    </Form.Item>

                    <div className="grid grid-cols-4 gap-x-2">
                        {/* Show mandate fields if the checkbox is checked */}
                        {mandateChecked[field.key] && (
                            <>
                                <Form.Item
                                    label="Valtuutetun nimi"
                                    name={[field.name, "mandateName"]}
                                    rules={[
                                        {
                                            required: mandateChecked[field.key],
                                            message: "Valtuutetun nimi on pakollinen",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Valtuutetun nimi" />
                                </Form.Item>
                                <Form.Item
                                    label="Valtuutetun sähköposti"
                                    name={[field.name, "mandateEmail"]}
                                    rules={[
                                        {
                                            required: mandateChecked[field.key],
                                            message: "Valtuutetun sähköposti on pakollinen",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Valtuutetun sähköposti" />
                                </Form.Item>
                                <Form.Item
                                    label="Valtuutetun henkilötunnus"
                                    name={[field.name, "mandateSsn"]}
                                    rules={[
                                        {
                                            required: mandateChecked[field.key],
                                            message: "Valtuutetun henkilötunnus on pakollinen",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Valtuutetun henkilötunnus" />
                                </Form.Item>
                                <Form.Item
                                    label="Valtuutetun puhelinnumero"
                                    name={[field.name, "mandatePhonenumber"]}
                                    rules={[
                                        {
                                            required: mandateChecked[field.key],
                                            message: "Valtuutetun puhelinnumero on pakollinen",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Valtuutetun puhelinnumero" />
                                </Form.Item>
                            </>
                        )}
                    </div>
                </div>
            ))}
            {!isCompany && auctionType !== 'rent' && (
                <Form.Item>
                    <Button type="dashed" onClick={add} block icon={<PlusOutlined />}>
                        Lisää {label.toLowerCase()}
                    </Button>
                </Form.Item>
            )}
        </>
    );
};

export default ContractPersonForm;
