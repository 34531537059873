import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Breadcrumbs from "../common/Breadcrumbs";

const MainLayout = ({ breadcrumbsVisible, children }) => {
    return (
        <>
            <div className="flex flex-col h-dvh">
                <Header pageType={1} />
                <div className="px-5 2xl:px-0 flex-grow">
                    {breadcrumbsVisible && <Breadcrumbs />}
                    <>{children}</>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default MainLayout;
