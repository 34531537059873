import React, { useState, useEffect } from "react";
import { Table, InputNumber, Button, message } from "antd";
import api from "../../services/Api";

const Products = () => {
    const [products, setProducts] = useState([]);
    const [updatedProducts, setUpdatedProducts] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);

            try {
                const response = await api.getProducts();
                setProducts(response);
            } catch (error) {
                console.error("Error fetching products:", error);
                message.error("Failed to fetch products.");
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const handlePriceChange = (id, value) => {
        setProducts((prevProducts) =>
            prevProducts.map((product) =>
                product.id === id ? { ...product, price: value } : product
            )
        );

        setUpdatedProducts((prev) => ({
            ...prev,
            [id]: value,
        }));
    };

    const handleSave = async () => {
        const updates = Object.keys(updatedProducts).map((id) => ({
            id: parseInt(id, 10),
            price: updatedProducts[id],
        }));

        if (updates.length === 0) {
            message.warning("Ei muutoksia tallennettavaksi.");
            return;
        }

        try {
            await api.saveProducts(updates);
            message.success("Tuotehinnat päivitetty.");
            setUpdatedProducts({});
        } catch (error) {
            console.error("Error updating prices:", error);
            message.error("Failed to update prices.");
        }
    };

    const columns = [
        {
            title: "Tuote",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Hinta (€)",
            dataIndex: "price",
            key: "price",
            render: (price, record) => (
                <InputNumber
                    min={0}
                    step={0.01}
                    value={price}
                    onChange={(value) => handlePriceChange(record.id, value)}
                    style={{ width: "100px" }}
                />
            ),
        },
    ];

    return (
        <div>
            <h1>Tuotteet</h1>
            <Table
                columns={columns}
                dataSource={products}
                rowKey="id"
                loading={loading}
                pagination={false}
            />
            <Button
                type="primary"
                onClick={handleSave}
                style={{ marginTop: "20px" }}
            >
                Tallenna
            </Button>
        </div>
    );
};

export default Products;
