// PropertyFormBasicInfo.js
import React from "react";
import { Form, Input, Select } from "antd";
import CKEditorWrapper from "../common/CKEditorWrapper";

const PropertyFormBasicInfo = ({ initialValues, fieldsDisabled, isUserProperty, description, setDescription }) => {
    const handleEditorChange = (content) => {
        setDescription(content);
    };

    return (
    <>
        <div className="flex justify-start gap-5">
            <Form.Item
                label="Nimi"
                name="title"
                rules={[{ required: true, message: "Nimi on pakollinen" }]}
                className="flex-1"
            >
                <Input disabled={fieldsDisabled} />
            </Form.Item>
            <Form.Item
                label="Paikka"
                name="area"
                rules={[{ required: true, message: "Paikka on pakollinen" }]}
                className="flex-1"
            >
                <Input disabled={fieldsDisabled} />
            </Form.Item>
            <Form.Item
                label="Omistusmuoto"
                name="ownershipType"
                className="flex-1"
                rules={[{ required: true, message: "Omistusmuoto on pakollinen" }]}
            >
                <Select>
                    <Select.Option value="week">Viikko-osake</Select.Option>
                    <Select.Option value="1/4">1/4 osuus</Select.Option>
                    <Select.Option value="1/6">1/6 osuus</Select.Option>
                    <Select.Option value="1/8">1/8 osuus</Select.Option>
                    <Select.Option value="1/10">1/10 osuus</Select.Option>
                    <Select.Option value="other">Muu omistus</Select.Option>
                </Select>
            </Form.Item>
            {!isUserProperty && (
                <Form.Item label="Y-tunnus" name="businessId" className="flex-1">
                    <Input disabled={fieldsDisabled} />
                </Form.Item>
            )}
        </div>
        <Form.Item label="Verkkosivu" name="website">
            <Input disabled={fieldsDisabled} />
        </Form.Item>
        <Form.Item label="Kuvaus" name="description">
            <CKEditorWrapper
                content={description}
                setContent={handleEditorChange}
                disabled={fieldsDisabled}
                height="300px"
            />
        </Form.Item>
        <div className="flex gap-5">
            <div className="flex-1">
                <div className="text-lg mb-5">Kohteen osoitetiedot</div>
                <Form.Item label="Osoite" name="address" rules={[{ required: true, message: "Osoite on pakollinen" }]}>
                    <Input disabled={fieldsDisabled} />
                </Form.Item>
                <div className="flex gap-5">
                    <Form.Item
                        label="Postinumero"
                        name="zipcode"
                        className="w-32"
                        rules={[{ required: true, message: "Postinumero on pakollinen" }]}
                    >
                        <Input disabled={fieldsDisabled} />
                    </Form.Item>
                    <Form.Item
                        label="Kaupunki"
                        name="city"
                        rules={[{ required: true, message: "Kaupunki on pakollinen" }]}
                    >
                        <Input disabled={fieldsDisabled} />
                    </Form.Item>
                    <Form.Item label="Maa" name="country" rules={[{ required: true, message: "Maa on pakollinen" }]}>
                        <Input disabled={fieldsDisabled} />
                    </Form.Item>
                </div>
            </div>
            {!isUserProperty && (
                <div className="flex-1">
                    <div className="text-lg mb-5">Avainpalvelun osoitetiedot</div>
                    <Form.Item label="Osoite" name="serviceAddress">
                        <Input disabled={fieldsDisabled} />
                    </Form.Item>
                    <div className="flex gap-5">
                        <Form.Item label="Postinumero" name="serviceZipcode" className="w-1/3">
                            <Input disabled={fieldsDisabled} />
                        </Form.Item>
                        <Form.Item label="Kaupunki" name="serviceCity">
                            <Input disabled={fieldsDisabled} />
                        </Form.Item>
                        <Form.Item label="Maa" name="serviceCountry">
                            <Input disabled={fieldsDisabled} />
                        </Form.Item>
                    </div>
                </div>
            )}
        </div>
    </>
)};

export default PropertyFormBasicInfo;
