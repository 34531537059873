import React, { useEffect, useState } from "react";

import api from "../../services/Api";
import { Modal, Popconfirm, Spin, Table } from "antd";
import { Link } from "react-router-dom";

const UserModal = ({ user, open, onClose }) => {
    const [loading, setLoading] = useState(true);
    const [auctions, setAuctions] = useState([]);

    const statusColors = {
        draft: "#f9a826",
        published: "#3bb54a",
        sold: "#d0021b",
        expired: "#b0b0b0",
        cancelled: "#ee4400",
    };

    useEffect(() => {
        setLoading(true);

        const fetchUser = async () => {
            try {
                const response = await api.getUserAuctions(null, 0, user.id);
                setAuctions(response);
            } catch (error) {
                console.error("Error fetching user:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [user]);

    return (
        <Modal title="Käyttäjän tiedot" open={open} footer={null} onCancel={onClose}>
            {!user ? (
                <Spin />
            ) : (
                <>
                    <div className="grid grid-cols-2 gap-4">
                    <div className="font-bold">Nimi</div> <div>{user.fullname}</div>
                    <div className="font-bold">Sähköposti</div>
                        <div>
                            <a href={`mailto:${user.email}`} className="text-blue-700 underline">
                                {user.email}
                            </a>
                        </div>
                        <div className="font-bold">Puhelinnumero</div> <div>{user.phonenumber}</div>
                        <div className="font-bold">Osoite</div> <div>{user.address}</div>
                        <div className="font-bold">Postinumero</div> <div>{user.postalCode}</div>
                        <div className="font-bold">Kaupunki</div> <div>{user.city}</div>
                        <div className="font-bold">Maa</div> <div>{user.country}</div>
                        <div className="font-bold">Liittynyt</div> <div>{new Date(user.createdAt.date).toLocaleString('fi-FI')}</div>
                    </div>

                    {user.company && (
                        <div>
                            <h2>Yritys</h2>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="font-bold">Yrityksen nimi</div> <div>{user.company.name}</div>
                                <div className="font-bold">Y-tunnus</div> <div>{user.company.businessId}</div>
                            </div>
                        </div>
                    )}

                    {auctions?.length > 0 && (
                        <div className="mt-5">
                            <h2>Ilmoitukset ({auctions.length})</h2>
                            <Table
                                dataSource={auctions}
                                loading={loading}
                                size="small"
                                columns={[
                                    {
                                        title: "#",
                                        dataIndex: "number",
                                        key: "number",
                                        sorter: true,
                                        defaultSortOrder: "ascend",
                                        render: (number, record) => (
                                            <div
                                                className="text-center text-white rounded p-1"
                                                style={{ backgroundColor: `${statusColors[record.status]}` }}
                                            >
                                                {number}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: "Nimi",
                                        dataIndex: "title",
                                        key: "title",
                                        sorter: true,
                                        defaultSortOrder: "ascend",
                                        render: (title, record) => (
                                            <Link to={`/oma-sivu/ilmoitukset/${record.uuid}`} className="underline text-blue-700">
                                                {title}
                                            </Link>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    )}

                    {/* <div>
                <h2>Actions</h2>
                <Popconfirm title="Are you sure you want to delete this user?" onConfirm={() => {}}>
                    <button className="btn btn-red">Delete</button>
                </Popconfirm>
            </div> */}
                </>
            )}
        </Modal>
    );
};

export default UserModal;
