import React, { useContext, useEffect, useState } from "react";

import { useParams, Link } from "react-router-dom";

import api from "../../services/Api";
import ContractForm from "../../components/forms/ContractForm";
import { AuthContext } from "../../context/AuthContext";
import { Spin } from "antd";
import { DownloadOutlined, FileAddOutlined, FileOutlined } from "@ant-design/icons";

const Contract = () => {
    const { user, hasRole } = useContext(AuthContext);
    const [auction, setAuction] = useState(null);
    const [loading, setLoading] = useState(true);

    const { uuid } = useParams();

    useEffect(() => {
        setLoading(true);

        const fetchAuction = async () => {
            try {
                const response = await api.getUserAuction(uuid);
                setAuction(response);
            } catch (error) {
            } finally {
                setLoading(false);
            }
        };

        fetchAuction();
    }, [uuid]);

    return (
        <>
            {loading ? (
                <Spin />
            ) : (
                <>
                    <div className="text-base mb-5">
                        <h1>
                            {auction.auctionType === "rent" ? "Vuokrasopimus" : "Kauppakirja"} nro {auction.number}
                        </h1>

                        <div className="font-bold uppercase">
                            {auction.auctionType === "rent" ? "Vuokrauksen" : "Kaupan"} kohde
                        </div>
                        <div className="mb-5">
                            {auction.property.title}, huoneisto {auction.roomNumber}
                            {auction.ownershipType === "week" && <>, viikko {auction.propertyWeek}</>}
                        </div>
                    </div>

                    {auction.contract?.isClosed && (
                        <Link
                            to={`${process.env.REACT_APP_API_URL}/sopimus/lataa/${auction.uuid}`}
                            className="bg-blue-700 !text-white px-2 py-1 rounded text-sm mb-5 inline-block"
                        >
                            <DownloadOutlined className="mr-2" /> Lataa sopimustiedosto
                        </Link>
                    )}

                    <ContractForm auction={auction} />
                </>
            )}
        </>
    );
};

export default Contract;
