import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Spin, message as antdMessage } from "antd";

import { AuthContext } from "../../context/AuthContext";
import { useChatContext } from "../../context/ChatContext";

import ChatMessages from "../../components/common/ChatMessages";
import ChatList from "../../components/common/ChatList";

import api from "../../services/Api";

const Chats = () => {
    const { user } = useContext(AuthContext);
    const { fetchUnreadMessagesCount, updateUnreadMessagesCount } = useChatContext();

    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedChat, setSelectedChat] = useState(null);
    const [replyContent, setReplyContent] = useState("");

    const { chatUuid } = useParams();
    const navigate = useNavigate();

    // Fetch user chats initially
    useEffect(() => {
        const fetchChats = async () => {
            try {
                const response = await api.getUserChats();
                setChats(response);
                setLoading(false);
            } catch (error) {
                // console.error("Error fetching chats:", error);
                // antdMessage.error("Error fetching chats");
                setLoading(false);
            }
        };

        fetchChats();
    }, []);

    // Periodically fetch messages for the selected chat
    useEffect(() => {
        if (selectedChat) {
            const intervalId = setInterval(async () => {
                try {
                    const response = await api.getChatMessages(selectedChat.uuid);
                    setSelectedChat((prevChat) => ({
                        ...prevChat,
                        messages: response,
                    }));
                    
                    // Update unread messages count after fetching new messages
                    const unreadCount = await fetchUnreadMessagesCount();
                    updateUnreadMessagesCount(unreadCount);

                    scrollToMessageBottom();
                } catch (error) {
                    // console.error("Error fetching messages:", error);
                    // antdMessage.error("Error fetching messages");
                }
            }, 5000); // Every 5 seconds

            return () => clearInterval(intervalId);
        }
    }, [selectedChat]);

    // Select chat by chatUuid and fetch messages
    useEffect(() => {
        if (chatUuid) {
            const chat = chats.find((chat) => chat.uuid === chatUuid);

            if (chat) {
                setSelectedChat(chat);
            }
        }
    }, [chatUuid, chats]);

    // Fetch messages for a selected chat and update unread count
    const fetchMessages = async (e) => {
        const chatUuid = e.currentTarget.dataset.id;

        try {
            const response = await api.getChatMessages(chatUuid);
            setSelectedChat({ ...selectedChat, messages: response, uuid: chatUuid });

            // Update unread messages count after fetching new messages
            const unreadCount = await fetchUnreadMessagesCount();
            updateUnreadMessagesCount(unreadCount);
        } catch (error) {
            // console.error("Error fetching messages:", error);
            // antdMessage.error("Error fetching messages");
        } finally {
            scrollToMessageBottom();
            navigate("/oma-sivu/viestit/" + chatUuid);
        }
    };

    const handleReplyChange = (e) => {
        setReplyContent(e.target.value);
    };

    const handleReplySubmit = async () => {
        if (!replyContent) {
            antdMessage.error("Viestin sisältö puuttuu");
            return;
        }

        const payload = {
            content: replyContent,
            chat_uuid: selectedChat.uuid,
        };

        try {
            const response = await api.sendChatMessage(payload);
            setSelectedChat((prevChat) => ({
                ...prevChat,
                messages: [...prevChat.messages, response],
            }));
            setReplyContent("");
            scrollToMessageBottom();
            //antdMessage.success("Message sent");
        } catch (error) {
            console.error("Error sending message:", error);
            //antdMessage.error("Error sending message");
        }
    };

    if (loading) {
        return <Spin />;
    }

    const scrollToMessageBottom = () => {
        const messages = document.getElementById("messages");

        if (messages) {
            messages.scrollTop = messages.scrollHeight;
        }
    };

    return (
        <div className="container max-w-screen-2xl m-auto text-lg">
            <h1 className="text-2xl mb-5">Viestit</h1>

            {selectedChat && (
                <button
                    className="bg-blue-500 text-white font-bold px-3 py-1 rounded mb-5"
                    onClick={() => {
                        setSelectedChat(null);
                        navigate("/oma-sivu/viestit");
                    }}
                >
                    Takaisin
                </button>
            )}

            <div>
                <div id="chats" className={`text-base ${selectedChat ? 'hidden' : ''}`}>
                    <ChatList chats={chats} fetchMessages={fetchMessages} />                    
                </div>
                <div>
                    {selectedChat && (
                        <ChatMessages
                            selectedChat={selectedChat}
                            user={user}
                            replyContent={replyContent}
                            handleReplyChange={handleReplyChange}
                            handleReplySubmit={handleReplySubmit}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Chats;
