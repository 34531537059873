import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Image, Spin } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";

import api from "../services/Api";

const URL = process.env.REACT_APP_API_URL;

const BlogList = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchBlogPosts();
    }, []);

    const fetchBlogPosts = async () => {
        setLoading(true);

        try {
            const response = await api.getBlogPosts();
            setBlogPosts(response);
        } catch (error) {
            console.error("Error fetching blog posts:", error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center absolute w-full bg-white bg-opacity-25 h-full z-20">
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div className="md:grid md:grid-cols-6 gap-5">
            {blogPosts.map((post) => (
                <div key={post.id} className="mb-5">
                    <Link to={`/artikkelit/${post.slug}`}>
                        <h4 className="text-xl font-medium text-primary hover:underline">{post.title}</h4>
                        <Image src={`${URL}/uploads/blog/${post.imageFilename}`} alt={post.title} preview={false} />
                        <p className="text-sm text-primary text-right">
                            <DoubleRightOutlined className="mr-2" />
                            Lue koko artikkeli
                        </p>
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default BlogList;
