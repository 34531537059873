import React, { useState } from "react";
import { Drawer } from "antd";
import { MenuOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Breadcrumbs from "../common/Breadcrumbs";
import Sidebar from "../common/Sidebar";

const AdminLayout = ({ sidebar = true, children }) => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    return (
        <div className="flex flex-col h-dvh">
            <Header pageType={1} />
            <div className="px-5 2xl:px-0 flex-grow">
                <Breadcrumbs />

                <main>
                    <div className="lg:hidden mb-4 ">
                        <div
                            type="primary"
                            icon={<MenuOutlined />}
                            onClick={toggleSidebar}
                            className="text-lg uppercase px-2 p-0 bg-primary text-white inline-block rounded"
                        >
                            <MenuUnfoldOutlined />
                            <span className="ml-2">Valikko</span>
                        </div>
                    </div>
                    <div className="container m-auto max-w-7xl flex">
                        {sidebar && (
                            <div className="hidden lg:block lg:w-1/6 lg:mr-5">
                                <Sidebar />
                            </div>
                        )}

                        <Drawer
                            title="Valikko"
                            placement="left"
                            closable={true}
                            onClose={toggleSidebar}
                            open={isSidebarVisible}
                        >
                            <Sidebar onLinkClick={toggleSidebar} />
                        </Drawer>

                        <div className={sidebar ? "lg:w-5/6 w-full" : "w-full"}>{children}</div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};

export default AdminLayout;
